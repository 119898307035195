@import 'src/assets/theme';

.loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #fff8;
}

.container {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;

  @media print {
    display: none;
  }

  @media (max-width: $tablet-screen-max-content) {
    padding: 8px;
  }

  .logoLoading {
    display: flex;

    .logoLoadingImg {
      width: 150px;
      height: 50px;
      border-radius: 8px;
    }
  }

  .logoImageWrapper {
    display: flex;
    align-items: center;

    .logoImage {
      max-height: 80px;
    }
  }

  .profileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-header-text);

    .profileBox {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (min-width: $tablet-screen-max-content) {
        position: relative;
      }

      .profile {
        height: 70px;
        width: 70px;

        @media (max-width: $tablet-screen-max-content) {
          height: 60px;
          width: 60px;
        }

        @media (max-width: $mobile-screen-max-content) {
          display: none;
        }
      }
    }

    .logOutBtn {
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-top: $standard-padding * 2;

      .icon {
        padding-left: 4px;
      }
    }
  }

  .loginWrapper {
    display: flex;

    .login {
      font-size: 20px;
      line-height: 26px;
      text-decoration-line: underline;
      color: var(--color-primary);
      cursor: pointer;

      @media (max-width: $tablet-screen-max-content) {
        font-size: 16px;
      }
    }

    .loginWhite {
      @extend .login;
      color: var(--color-white);
    }
  }
}

.noPadding {
  padding: 0 0 16px;
}
