@import 'src/assets/theme';

.label {
  user-select: none;

  .matchedValue {
    text-decoration: underline;
  }
}

.listBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: var(--color-grey);
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:hover:not(.disabled) {
    color: var(--color-primary);
    background-color: var(--color-primary-10);
  }

  &.disabled {
    cursor: not-allowed;
  }

  .rightArrow {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
  }
}

.selectedListBox {
  @extend .listBox;
  font-weight: 600;
  color: var(--color-primary);
  background-color: var(--color-primary-10);
}
