.container {
  display: flex;
  cursor: pointer;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    margin: 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    border: 2px solid var(--color-grey-50);
    background-color: transparent;
    border-radius: 4px;
    color: var(--color-grey-50);
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:checked {
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      color: var(--color-white);
      background-color: var(--color-primary);
      border: 2px solid var(--color-primary);

      &:after {
        content: '';
        display: flex;
        width: 4px;
        height: 11px;
        position: relative;
        top: -2px;
        border: solid var(--color-white);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  .checkBoxLabel {
    margin-left: 8px;
    user-select: none;
  }
}

.disabledContainer {
  @extend .container;
  cursor: not-allowed;

  .checkbox {
    cursor: not-allowed;
    background-color: var(--color-grey-50) !important;
    border: 2px solid var(--color-grey-50) !important;
  }

  .checkBoxLabel {
    color: var(--color-grey-50);
  }
}
