@import 'src/assets/theme';

.container {
  position: relative;
  padding: 20px;

  .badge {
    display: flex;
    align-items: center;
    padding-left: 100px;
    background: var(--color-secondary-10);
    border-radius: 4px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-top: 4px;
    padding-bottom: 4px;

    .verifiedIcon {
      margin-left: 4px;
      font-size: 16px;
    }
  }

  .content {
    border: 1px solid var(--color-grey-50);
    background-color: var(--color-white);
    border-radius: 4px;
    padding: 12px 16px;

    .avatarSection {
      .avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 110px;
        height: 110px;
        background-color: var(--avatar-default-bg-color);
        border-radius: 50%;

        @media (max-width: $medium-screen-min-width) {
          width: 90px;
          height: 90px;
        }
      }
    }

    .basicInfo {
      padding-left: 92px;
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 700;
        font-size: 21px;
      }

      .pronouns {
        font-size: 12px;
        color: var(--color-light-text);
        min-width: max-content;
      }

      .deliveryIcon {
        font-size: 20px;
      }

      .language {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 600;
        flex-flow: wrap;

        .text {
          border-right: 2px solid var(--color-secondary);
          padding-right: 4px;
          padding-left: 4px;
        }

        :last-child {
          border-right: none !important;
        }
      }
    }

    .details {
      padding-bottom: 8px;
    }
  }

  .rowWrapper {
    display: flex;
    gap: 8px;
    margin-bottom: 14px;
    align-items: center;
    flex-wrap: wrap;
  }
}

.badgeContainer {
  //width: 70%;
  margin: 20px auto;
}

.details :global {
  .readMore {
    color: var(--color-clickable-text);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
}
