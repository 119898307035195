.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--color-primary-text);
  padding: 0 7%;
}

.contents {
  background-color: var(--color-white);
  gap: 32px;
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 65%;
    border-radius: 8px;
    @media (max-width: 800px) {
      width: 100%;
    }

    .infoContainer {
      border: 1px solid var(--color-grey-50);
      box-shadow: 0px 4px 8px 0px #0000001a;
      padding: 24px 32px;
      border-radius: 8px;

      .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      .desc {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;

        .checkBoxText {
          display: flex;
          flex-direction: row;
          height: fit-content;
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;

          .checkBox {
            vertical-align: top;
            align-items: center;

            input {
              outline: none;
              border: 2px solid var(--color-grey-50);
              margin-right: 8px;
            }
          }
        }
      }
    }

    .continueButton {
      width: 50%;
    }
  }

  .rightContent {
    width: 35%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
