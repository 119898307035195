@import 'src/assets/theme';

.container {
  display: flex;
  background-color: var(--color-white);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 20px;
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .cardBox {
        display: flex;
        padding: 16px 12px;
        border-radius: 8px;
        border: 1px solid var(--color-white);
        cursor: pointer;

        .cardLabel {
          align-items: flex-start;
          width: 100%;
        }

        .cardContainer {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .cardDisabledContainer {
          margin-top: -9px;
        }

        .selectedCardInfo {
          justify-content: space-between;
        }

        .cardInfo {
          display: flex;
          align-items: center;
          margin: 3px 0 0;

          .appointmentName {
            color: var(--color-primary);
            font-size: 16px;
            font-weight: 700;
          }

          .disabledName {
            color: var(--color-grey-50);
            font-size: 16px;
            font-weight: 600;
          }

          .otherInfoWrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-left: 16px;
          }

          .noAvailableWrapper {
            display: flex;
            align-items: center;
            padding: 8px;
            border-radius: 8px;
            background: var(--color-warning-10);
            color: var(--color-warning);
            margin-left: 16px;
            gap: 4px;
            font-weight: 600;
            user-select: none;

            .calendarIcon {
              font-size: 21px;
            }
          }
        }

        .cardDesc {
          color: var(--color-secondary-text);
          font-size: 14px;
          line-height: 18px;
          margin-top: 16px;
        }
      }

      .activeCardBox {
        background-color: var(--color-primary-10);
        border: 1px solid var(--color-primary-30);
      }
    }
  }

  .availabilityHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .filterWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .title {
      margin-bottom: 0;
    }
  }

  .availabilityContent {
    display: flex;
    justify-content: space-between;
  }

  .dropdownControllerWrapper {
    border-radius: 60px;
    border: 1px solid var(--color-primary);
    background: var(--color-white);
    padding: 4px 8px;
  }

  .labelWrapper {
    display: flex;
    gap: 8px;

    > *:not(:last-child)::after {
      content: '|';
      margin-left: 8px; // Space between the item and the separator
      color: var(--color-primary); // Customize separator color
    }
  }

  .label {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
  }

  .dividerLine {
    border: 1px solid var(--color-grey-50);
    margin: 24px 0;
  }
}

.infoItemBox {
  display: flex;
  align-items: center;

  .infoIcon {
    color: var(--color-primary);
    font-size: 18px;
  }

  .infoText {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    gap: 8px;
    white-space: nowrap;
  }

  .deliveryOptionWrapper {
    min-width: 96px;
  }
}

.desktopAppType {
  display: flex;

  @media (max-width: $medium-screen-min-height) {
    display: none;
  }
}

.mobileAppType {
  display: none;

  @media (max-width: $medium-screen-min-height) {
    display: flex;
  }
}

.mobileAppTypeContainer {
  width: 100%;
}

.dropdownListWrapper {
  padding: 0 !important;
}

.mobileAppTypeDropdownControllerWrapper {
  border-radius: 8px;
  border: 1px solid var(--color-primary-30) !important;
  background: var(--color-primary-10) !important;
  padding: 12px !important;
  min-width: 100% !important;
  box-sizing: border-box;
}

.mobileAppTypeLabel {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
}

.mobileAppTypeLabelWrapper {
  display: flex;
  gap: 8px;
}

.mobileAppTypeOptionWrapper {
  min-width: 100% !important;
}

.mobileAppTypeDropdownWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: var(--color-primary);

  .mobileAppTypeTitle {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .mobileAppTypeInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }

  .mobileAppTypeCommWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.mobileAppTypeDropdownListWrapper {
  @extend .mobileAppTypeDropdownWrapper;
  padding: 12px !important;
}

.selectedAppType {
  background-color: var(--color-primary-10);
}
