@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--fit-filter-bg-color);
  color: var(--fit-filter-text-color);

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: column;
      align-items: unset;
      gap: 24px;
    }
  }

  .filterWrapper {
    display: flex;
    flex: 1;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $medium-desktop-screen-max-content) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin: 0 -8px;
      padding: 0 8px;
    }
  }

  .showWhenMobile {
    display: none;

    @media (max-width: $tablet-screen-max-content) {
      display: flex;
    }
  }

  .hideWhenMobile {
    display: flex;

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }
  }

  .labelWrapper {
    flex-direction: column;
    gap: 4px;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    .desc {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .fitFilterWrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 24px;
    position: static;
    gap: 16px;

    @media (max-width: $tablet-screen-max-content) {
      margin-left: 0;
    }

    .dropdownStyle {
      min-height: 32px;
    }

    .gender {
      max-width: 180px;
      width: 100%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 100%;
      }
    }

    .days {
      @extend .gender;
      min-width: max-content;
    }

    .times {
      @extend .gender;
      max-width: 130px;
    }

    .deliveryMode {
      @extend .gender;
      max-width: 150px;
    }

    .language {
      @extend .gender;
      max-width: 160px;
    }

    .style {
      @extend .gender;
      max-width: 130px;
    }
  }

  .fitReset {
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 16px;
    user-select: none;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .viewIcon {
    cursor: pointer;

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }
  }

  .daysMenuWrapperClass {
    max-width: 245px;
    position: absolute;
    margin-top: 50px;
  }
}
