@import 'src/assets/theme';

.wrapper {
  background-color: $blue50;
  position: sticky;
  top: 100vh;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  color: $blue;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  font-family: $footer-font-stack;

  .tacklitLogo {
    width: 80px;
  }

  .copyrightSymbol {
    display: inline-block;
    margin-left: $standard-padding * 4;
    margin-right: $standard-padding;
    font-size: 16px;

    @media screen and (max-width: $medium-small-screen-min-width) {
      margin-left: $standard-padding;
    }
  }
}

.darkContainer {
  @extend .container;
  color: $white;
  background-color: #263054;

  a {
    color: $white;
  }
}
