@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 28px;
  .row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-direction: row;

    @media (max-width: $desktop-screen-max-content) {
      flex-wrap: wrap;
    }

    flex-wrap: wrap;

    .field {
      width: 50%;
      flex-basis: calc((100% - 30px) / 2);
    }

    .fieldWrapper {
      width: 100%;
      color: var(--color-primary-text);

      .selectInputLabel {
        font-size: 12px;
        color: var(--color-primary-text);
      }
    }

    .toggle {
      display: flex;
      flex-direction: column;
      gap: 8px;
      label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }

    .checkBoxText {
      display: flex;
      flex-direction: row;
      height: fit-content;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      .checkBox {
        vertical-align: top;
        align-items: center;

        input {
          outline: none;
          border: 2px solid var(--color-grey-50);
          margin-right: 8px;
        }
      }
    }
  }
}
