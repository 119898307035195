@import 'src/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px;
  color: var(--color-primary-text);
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  transition: 0.2s ease;
  cursor: pointer;
  background-color: var(--color-card-background2);

  &:hover,
  &:active {
    background: var(--color-primary-50);
    color: var(--color-white);

    .arrowIcon {
      color: var(--color-white);
      border: solid 1px var(--color-white);
    }
  }

  .arrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: var(--color-primary-text);
    font-size: 16px;
    border: solid 1px var(--color-primary-text);
    border-radius: 4px;
  }
}
