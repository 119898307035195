@import 'src/assets/theme';

.container {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 600;
  color: $colorError;

  &.padLeft {
    padding-left: 4px;
  }
}
