.container {
  .contentContainer {
    border-bottom: 1px solid var(--color-grey-10);
    padding: 24px 0;
  }

  .timerContainer {
    @extend .contentContainer;
    .timerLabel {
      margin-top: 12px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .appointmentInfo {
    @extend .contentContainer;
    row-gap: 20px;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      justify-content: space-between;
      column-gap: 30px;

      .fieldData {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 10px;
          font-weight: 600;
          color: var(--color-grey);
          transform: uppercase;
        }
        .detail {
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
        }
        .onHoldStatus {
          font-size: 12px;
          color: var(--color-warning);
          border: 1px solid var(--color-warning);
          border-radius: 8px;
          padding: 12px;
        }
        .timeZone {
          font-size: 12px;
          color: var(--color-primary-50);
        }
      }
    }
  }

  .whyUs {
    @extend .contentContainer;
    .title {
      font-size: 10px;
      font-weight: 600;
      color: var(--color-grey);
      transform: uppercase;
    }
    .detail {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}
