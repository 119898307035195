@import 'src/assets/theme';

.loading::before {
  content: ' ';
  display: block;
  width: 17px;
  height: 17px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid var(--color-primary-inverted);
  border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.7) rgba(255, 255, 255, 0.8) rgba(255, 255, 255, 0.9);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}
