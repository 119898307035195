@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--color-primary-text);

  .headerWrapper {
    background: var(--filter-bg-color);

    .filterWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .timezone {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
      }
    }
  }

  .filterFitWrapper {
    padding: 16px 0;
    color: var(--color-white);
    background-color: var(--color-primary);
  }

  .content {
    background-color: var(--color-white);
    gap: 20px;
    flex: 1;
    padding: 40px 0;
  }

  .psychologistListWrapper {
    display: grid;
    grid-gap: 20px 0;

    &.gridView {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: $desktop-screen-max-content) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $tablet-screen-max-content) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &.listView {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .loadingWrapper {
    border: 1px solid var(--color-grey-50);
    background-color: var(--color-white);
    border-radius: 4px;
    padding: 12px 16px;
    margin: 20px;
    position: relative;
  }
}
