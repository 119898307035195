@import 'src/assets/theme';

.container {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  background: white;
  z-index: 1;

  @media (min-width: $tablet-screen-max-content) {
    position: static;
    height: 100%;
    max-height: 91px;
    padding: 8px;
    border-radius: 4px;
    background: $colorGrey50;
    box-shadow: none;
    overflow: auto;
  }

  .title {
    color: var(--color-primary-text);
    font-weight: 700;

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }
  }

  .loading {
    color: var(--color-primary-text);

    @media (max-width: $tablet-screen-max-content) {
      display: none;
    }
  }

  .addressesContainer {
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    @media (min-width: $tablet-screen-max-content) {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .address {
    display: flex;
    padding: 2px;
    transition: background-color 0.1s ease;
    user-select: none;

    &:active {
      background: var(--color-secondary-10);
    }

    .icon {
      margin-top: 2px;
      margin-right: 3px;
      font-size: 12px;
      color: var(--color-grey);
    }

    .darkAddress {
      color: var(--color-primary-text);
    }

    .lightAddress {
      color: var(--color-grey-50);
    }
  }
}
