@import 'src/assets/theme';

.custom-theme {
  .mbsc-font {
    font-family: $base-font-stack;
  }

  .mbsc-calendar-grid-item {
    padding-top: 3.5em;
    background-color: $white;
  }

  .mbsc-calendar-grid {
    padding: 0;
  }

  .mbsc-calendar-header.mbsc-ios {
    border-bottom: solid 1px var(--color-primary);
    margin-bottom: 20px;
    border-radius: 8px 8px 0 0;
  }

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    font-size: 11px !important;
  }

  .mbsc-datepicker {
    border-radius: 7px;
  }

  .mbsc-ios.mbsc-datepicker-inline {
    border: none !important;
    width: 100%;
  }

  .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper {
    border-bottom: none;
  }

  .mbsc-ios.mbsc-calendar {
    border-radius: 8px;
  }

  .mbsc-ios.mbsc-calendar-month-title {
    color: var(--color-primary);
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    margin: -2em 36px 0 !important;

    @media (max-width: calc($small-desktop-screen-max-content + 100px)) {
      margin: -2em 32px 0 !important;
    }
  }

  .mbsc-ios.mbsc-calendar-day-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    line-height: 18px !important;
    padding: 8px !important;
    margin: 0 !important;

    @media (max-width: $tablet-screen-max-content) {
      font-size: 16px !important;
      line-height: 21px !important;
      padding: 0 !important;
      width: 2em !important;
      height: 2em !important;
    }
  }

  .mbsc-ios.mbsc-calendar-week-day {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-text);
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }

  [aria-label='Saturday'] {
    color: var(--color-warning) !important;
  }

  [aria-label='Sunday'] {
    color: var(--color-warning) !important;
  }

  .mbsc-datepicker .mbsc-calendar-slide {
    padding: 0;
  }

  .mbsc-calendar-cell {
    height: 44px;
  }

  .mbsc-calendar-cell-text {
    color: var(--color-primary);
  }

  .mbsc-segmented {
    max-width: 300px;
    margin: 0 auto;
  }

  .cal-header-picker {
    flex: 1 0 auto;
  }

  .mbsc-ios.mbsc-calendar-header {
    background: $white !important;
    padding: 0;
    border-bottom: none;
    margin-bottom: 12px;
  }

  .mbsc-calendar-controls {
    min-height: auto;
  }

  .mbsc-disabled {
    background: none !important;
  }

  .mbsc-disabled .mbsc-calendar-cell-text {
    color: var(--color-grey-50);
    background-color: var(--color-grey-10);
    border-radius: 4px;
    opacity: 1;
    font-weight: 500;
    height: 1.444445em;
  }

  .mbsc-calendar-day-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .mbsc-ios.mbsc-calendar-grid .mbsc-calendar-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  .mbsc-ios.mbsc-calendar-marks {
    margin: 24px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;

    @media (max-width: $mobile-screen-max-content) {
      margin: 16px auto 0;
      max-width: 30px;
    }
  }

  .mbsc-ios.mbsc-calendar-mark {
    margin: 0.0625em !important;

    @media (max-width: $mobile-screen-max-content) {
      margin: 0.0325em !important;
    }
  }

  .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    color: $white;
    background: var(--color-primary) !important;
    border-radius: 4px;
    border: 2px solid var(--color-primary-30);
  }

  .mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    background-color: #24306220;
    border-radius: 4px;
  }

  .mbsc-calendar-grid .mbsc-calendar-table {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .mbsc-ios.mbsc-calendar-grid-view .mbsc-calendar-title {
    font-size: 18px;
    line-height: 23px;
    color: var(--color-primary);
  }

  .mbsc-calendar-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: var(--color-primary);
    @media (max-width: $mobile-screen-max-content) {
      font-size: 18px !important;
    }
  }

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: var(--color-primary) !important;
    font-weight: 500;
    font-size: 11px;
    line-height: 21px;
  }

  .mbsc-ios.mbsc-calendar-today {
    color: var(--color-primary);
  }

  .mbsc-calendar-grid-row {
    background-color: $white;
  }

  .mbsc-ios-dark.mbsc-calendar-mark {
    background: #cccccc !important;
  }
}
