@import 'assets/theme';

.container {
  padding: 0 calc((100% - 1120px) / 2);
  margin-top: 8px;

  // @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
  //   padding: 0;
  //   display: flex;
  // }

  .row {
    display: flex;
    flex-direction: row;
    gap: 28px;
    margin-bottom: 28px;
    width: 100%;

    // @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
    //   flex-direction: column;
    //   gap: 20px;
    // }

    .field {
      width: 50%;
      flex-basis: calc((100% - 28px) / 2);
    }

    .fieldWrapper {
      width: 100%;
      max-width: 345px;
      color: var(--color-primary-text);

      .selectInputLabel {
        font-size: 12px;
        color: var(--color-primary-text);
      }
    }

    .checkBoxText {
      display: flex;
      flex-direction: row;
      height: fit-content;
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      .checkBox {
        vertical-align: top;
        align-items: center;

        input {
          outline: none;
          border: 2px solid var(--color-grey-50);
          margin-right: 8px;
        }
      }
    }
  }

  .fieldContainer {
    margin-bottom: 8px;
  }

  .desc {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #202225;
  }

  .dateFieldContainer {
    margin-top: -20px;
  }

  .gpDateFieldContainer {
    margin-top: -20px;
  }

  .referralDocuments {
    .label {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      //color: $colorGrey700;
    }
  }
}
