// grey series
$grey-darker: #424242;
$mid-grey-dark: #757575;
$mid-grey-medium: #8b8b8b;
$mid-grey: #bdbdbd;
$mid-grey-light: #e0e0e0;
$grey-light: #eeeeee;
$white: #ffffff;
$black: #000000;
$blue: #243062;

// main color
$main-blue: #2a4670;
$main-red: #fc8468;
$cta: #3f52fb;
$selected: #3f52fbc0;

$blue50: #f5f6ff;

// Colors
$colorBlue1: #3f52ff;
$colorBlue2: #2d9cdb;
$colorBlue3: #2a4670;
$colorBlack: black;
$colorWhite: white;
$colorLineGray: #d3d8dd; // Only use on line

$colorGrey50: #f9f9fa;
$colorGrey100: #ecedef;
$colorGrey400: #b5b8bd;
$colorGrey500: #94969d;
$colorGrey600: #61666e;
$colorGrey700: #414449;
$colorGrey800: #202225;

$colorTextualGray: #818e9b; // Only use on textual
$colorTextualBlack: #192a3e; // Only use on textual
$colorTextualDisabled: #c2c2c2;

$colorError: #d62828;

$text-disable-grey: #aaaaaa;
