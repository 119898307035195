@import 'src/assets/theme';

.container {
  width: 100%;
  line-height: 1.33;

  .title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary-text);

    @media (min-width: $tablet-screen-max-content) {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .taskButton {
    padding: 8px 12px;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;

    @media (min-width: $tablet-screen-max-content) {
      font-size: 18px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .noTasks {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-primary-text);

    @media (min-width: $tablet-screen-max-content) {
      font-size: 18px;
    }
  }
}
