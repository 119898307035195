@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.title {
  color: var(--color-primary);
  font-size: 24px;
  font-weight: 700;
}

.saveButton {
  width: 150px;
}
