.container {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 28px;

  .checkBoxText {
    display: flex;
    flex-direction: row;
    height: fit-content;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    .checkBox {
      vertical-align: top;
      align-items: center;

      input {
        outline: none;
        border: 1px solid var(--color-grey-50);
        margin-right: 8px;
      }
    }
  }

  .policy {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 8px;
    }

    .desc {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: var(--color-grey);

      a {
        color: var(--color-clickable-text);
      }
    }
  }

  .error {
    color: var(--color-warning);
    font-size: 14px;
  }
}
