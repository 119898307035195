@import 'src/assets/theme';

.container {
  position: relative;

  button {
    &.fieldError {
      border-bottom: 1px solid var(--color-warning) !important;
    }
  }

  .fieldError {
    color: var(--color-warning);
  }
}

.errorLabel {
  font-size: 12px;
  margin: 0 0 0 4px;
}

.tooltip {
  max-width: 200px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 14px;
}

.dropdownController {
  display: flex;
  justify-content: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background: none;
  border: none;
  position: relative;
  padding: 0;
  min-height: 28px;
  align-items: center;
  color: var(--color-grey-50);

  .optionLabel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    margin-right: 4px;
    padding-left: 4px;

    .labelWrapper {
      display: flex;
    }

    .label {
      width: 100%;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fullLabel {
      max-width: 100% !important;
    }

    .subLabelContainer {
      width: fit-content;
      max-width: 300px;
    }

    .icon {
      margin-left: 4px;
      font-size: 20px;
      align-items: center;
    }
  }

  .menuListContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .borderBottom {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: var(--color-grey-50);

    &.active {
      background-color: var(--color-primary);
    }
  }

  &.disabled {
    color: var(--color-grey-50) !important;
    cursor: not-allowed;
  }

  &.disabledColor {
    background-color: var(--color-grey-10);
    border: 1px solid var(--color-grey-50) !important;

    &:hover {
      background-color: var(--color-grey-10) !important;
    }

    .label {
      color: var(--color-grey-50) !important;
    }

    .dropdownIcon {
      color: var(--color-grey-50) !important;
    }
  }
}

.boxDropdownController {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #dadbde;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: var(--color-white);

  .optionLabel {
    margin-right: 0 !important;
    padding-left: 0 !important;
  }
}

.optionsWrapper {
  position: absolute;
  background: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 145px;
  z-index: 9;

  &.top {
    bottom: 30px;
  }

  &.left {
    right: 0;
  }
}

.boxOptionsWrapper {
  width: 100%;
}

.mainOptions,
.options {
  padding: 8px 0;
}

.skeletonLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 28px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.optionItem {
  justify-content: flex-start !important;
}

.items {
  direction: rtl;
  max-height: 190px;
  overflow-y: auto;
  overflow-x: visible;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $colorBlue1;
  }

  .noItems {
    direction: ltr;
    padding: 12px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorTextualGray;

    i {
      color: $colorTextualGray;
      font-size: 20px;
      margin-right: 6px;
    }
  }

  .listLoading {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
  }
}

.searchBoxWrapper {
  padding: 8px 8px 4px 8px;
  min-width: 200px;

  .searchBox {
    margin-top: 0;
  }
}

.otherOptionsWrapper {
  position: relative;

  .otherOptions {
    top: 0;
    left: 100%;
    border-radius: 4px;
    background: $white;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    .scrollDownText {
      margin-bottom: 0;
    }
  }
}

.scrollDownText {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 12px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  user-select: none;
  padding-top: 4px;
  margin-bottom: -8px;

  &:hover {
    background-color: var(--color-primary-50);
  }

  .scroll {
    display: flex;
    align-items: center;
  }

  &.scrolledToBottom {
    opacity: 0;
    cursor: unset !important;
  }

  i {
    font-size: 16px;
  }
}

.dropdownIcon {
  color: var(--color-grey-50);
}

.iconError {
  color: var(--color-warning) !important;
}

.dropdownLabel {
  color: $colorGrey600;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
