@import 'src/assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media (max-width: $tablet-screen-max-content) {
    padding: 0 8px;
  }
}
