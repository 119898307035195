@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  line-height: 1.33;

  .title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary-text);

    @media (min-width: $tablet-screen-max-content) {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .description {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-primary-text);

    strong {
      color: var(--color-header-text);
      font-weight: 700;
    }

    @media (min-width: $tablet-screen-max-content) {
      font-size: 18px;
    }
  }

  .contactButton {
    height: auto;
    margin-top: 24px;
    padding: 12px 20px;

    @media (min-width: $tablet-screen-max-content) {
      margin-top: 12px;
    }
  }
}
