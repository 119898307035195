.modalContainer {
  @media screen and (min-width: (801px)) {
    min-width: 800px;
  }

  .modalHeader {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 18px;
      margin: 0;
      overflow: hidden;
    }
  }
}
