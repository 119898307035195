@import 'src/assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  max-height: 24px;
}

.badge {
  @extend .container;
  background: var(--color-white);
  color: var(--color-primary);
}

.selected {
  @extend .container;
  color: var(--color-white);
}

.deleteIcon {
  cursor: pointer;
  font-variation-settings: 'FILL' 1;
  color: var(--color-primary);
  font-size: 20px;
  margin-left: 8px;
}
