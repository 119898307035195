@import 'src/assets/theme';

.radio {
  display: flex;
  flex-wrap: wrap;

  &.vertical {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }

  .disabled {
    cursor: not-allowed !important;
  }
}

.default {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  margin-top: 8px;

  &.error {
    border: 1px solid var(--color-warning);
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    &:checked + label,
    &:active + label {
      color: var(--color-primary);
      font-weight: 600;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  label {
    background-color: transparent;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-left: 16px;
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ($medium-screen-min-width - 1)) {
    label {
      flex: 1 0 auto;
    }
  }
}

.button {
  display: flex;
  flex-wrap: wrap;
  border-radius: $standard-padding;
  margin-bottom: $standard-padding;

  @media only screen and (max-width: $medium-screen-min-width) {
    margin-bottom: 0;
    justify-content: center;
  }

  &:not(:last-child) {
    margin-right: 24px;

    @media only screen and (max-width: $medium-screen-min-width) {
      margin-right: 16px;
    }
  }

  &.error {
    label {
      border: 1px solid var(--color-warning);
      border-radius: $standard-padding * 2;
      margin-bottom: 0;
    }
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    display: none;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: var(--color-primary);
      color: #ffffff;

      &:hover {
        opacity: 0.8;
      }
    }

    &:active + label {
      background-color: var(--color-primary);
      color: #ffffff;
    }
  }

  label {
    background-color: #0085bd30;
    color: #343434;
    font-size: 12px;
    min-width: 72px;
    max-width: 480px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    padding: 10px 12px;
    border-radius: 24px;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-right: 40px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      font-size: 11px;
      font-weight: 700;
      padding: 3px;
      color: white;
      background-color: var(--color-primary);
      border-radius: 100%;
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-primary-30);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #192a3e;
  margin: 8px 0;
  cursor: pointer;
}

.radioBtn {
  display: flex;
  align-items: center;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .label {
      &:before {
        content: '';
        background: var(--color-white);
        border-radius: 100%;
        border: 2px solid var(--color-primary);
        display: flex;
        min-width: 20px;
        height: 20px;
        position: relative;
        margin-right: 16px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .label {
        &:before {
          background-color: var(--color-primary);
          box-shadow: inset 0 0 0 4px var(--color-white);
          border: 2px solid var(--color-primary);
        }
      }
    }
    &:focus {
      + .label {
        &:before {
          outline: none;
          border-color: var(--color-primary);
        }
      }
    }
    &:disabled {
      + .label {
        cursor: not-allowed;
        &:before {
          box-shadow: inset 0 0 0 4px var(--color-white);
          border-color: var(--color-grey-50);
          cursor: not-allowed;
        }
      }
    }
    + .label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
