@import 'assets/theme';

.container {
  background-color: var(--color-primary-10);
  padding: 0 12px;
  border-radius: 50px;

  .wrapper {
    display: flex;
    position: relative;
    min-width: 100px;
    height: 64px;

    @media (max-width: $medium-small-screen-min-height) {
      height: 40px;
    }

    input[type='radio'] {
      display: none;
    }

    .pillWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      cursor: pointer;

      .label {
        display: flex;
        align-items: center;
        z-index: 1;
        font-weight: 600;
        color: var(--color-primary);
        padding: 0 16px;
        margin: 0 6px;
        cursor: pointer;
        font-size: 16px;
        line-height: 27px;
        user-select: none;

        .count {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6575b1;
          font-weight: bold;
          font-size: 14px;
          background: #d9dcff;
          border-radius: 40px;
          padding: 4px 8px;
          height: 24px;
          margin-left: 8px;
        }
      }

      .labelActive {
        @extend .label;
        color: var(--color-primary);
        font-weight: bold;
        font-weight: 700;

        .count {
          color: #fff;
          background: var(--color-primary);
        }
      }
    }

    .pillWrapperDisable {
      @extend .pillWrapper;
      opacity: 0.8;
      z-index: 2;
      pointer-events: none;
    }

    .indicator {
      position: absolute;
      top: 6px;
      left: 0;
      padding: 0;
      background-color: $white;
      background-image: linear-gradient(#ffffff33, transparent);
      transition: all 0.2s ease-out;
      box-shadow: 0 4px 2px #0000001a;
      border-radius: 25px;
      height: 50px;

      @media (max-width: $medium-small-screen-min-height) {
        top: 4px;
        height: 33px;
      }
    }
  }

  &.small {
    padding: 0 8px;

    .wrapper {
      height: 34px;
      .indicator {
        height: 26px;
        top: 4px;
      }

      .pillWrapper {
        .label {
          font-size: 14px;
          padding: 0 12px;
          margin: 0px;
        }

        .labelActive {
          .count {
            font-size: 14px;
            padding: 2px 6px;
          }
        }
      }
    }
  }
}
