@import 'src/assets/theme';

.container {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  background-color: var(--footer-background-color);
  border-radius: 24px 24px 0 0;

  .footerContent {
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (max-width: $tablet-screen-max-content) {
        padding: 12px 20px;
      }

      .description {
        color: var(--footer-text-color);
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .contactUsLabel {
        padding: 12px 20px;
        font-size: 24px;
        font-weight: 700;
      }

      .infoBox {
        display: flex;
        align-items: center;
        padding: 12px 20px;

        .infoIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          padding: 4px;
          margin-right: 18px;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          background-color: var(--color-white);
          color: var(--color-primary);
          font-variation-settings: 'FILL' 1;
        }
      }

      .crisisBox {
        border-radius: 8px;
        background: var(--color-warning-10);

        .infoIcon {
          color: var(--color-warning);
        }
      }
    }
  }
}
