@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .deliveryTypeOptions {
    display: flex;
    align-items: center;
    gap: 8px;

    .deliveryType {
      font-size: 18px;

      &.active {
        color: var(--color-primary);
      }

      &.inactive {
        color: var(--color-grey-50);
      }
    }
  }
}
