@import 'src/assets/theme';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  color: var(--color-primary-text);
  background: var(--header-background-color-inverted);
  overflow: auto;

  @media (max-width: $tablet-screen-max-content) {
    .footer {
      position: static;
    }
  }

  .content {
    gap: 20px;
    padding-bottom: 40px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    .flexContent {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (min-width: $tablet-screen-max-content) {
        flex-direction: row-reverse;
      }

      .topRightColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: $tablet-screen-max-content) {
          .contactSupport {
            display: none;
          }
        }
      }

      .bottomLeftColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (min-width: $tablet-screen-max-content) {
          .contactSupport {
            display: none;
          }
        }
      }
    }
  }
}
