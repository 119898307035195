@import 'src/assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  gap: 28px;

  @media (max-width: $medium-screen-min-height) {
    flex-direction: column;
  }

  .calendarWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 384px;
    border-radius: 8px;
    border: 1px solid var(--color-primary);
    background: var(--color-white);
    padding: 20px 16px 8px;

    @media (max-width: $medium-screen-min-height) {
      max-width: 100%;
      width: auto;
    }
  }

  .slotWrapper {
    @extend .calendarWrapper;
    max-width: 100%;

    .dateTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: var(--color-primary);
    }
  }
}

.customCalendarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
