.imgProfile {
  width: 100%;
  border-radius: 50%;
}

.avatarBg {
  background-color: #65c9ff;
}

.initialProfile {
  background: #f6a591;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #fff;

  .icon {
    font-size: 90px;
    color: #ffffff;
  }
}
