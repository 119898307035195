@import 'src/assets/theme';

.container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  .calendarCardWrapper {
    display: flex;
    gap: 12px;
  }

  .calendarSlotCard {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid var(--color-available-50);
    background-color: var(--color-available-10);
    padding: 4px 12px;
    align-items: flex-end;
    text-decoration: none;
    gap: 8px;
    color: var(--color-primary);

    .calendarDateInfo {
      display: flex;
      align-items: center;
      flex-direction: column;

      .date {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
      }

      .month {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .timeslotWrapper {
      display: flex;
      flex-direction: column;

      .day {
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
      }

      .time {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }

  .noAvailableWrapper {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 12px;
    background-color: var(--color-warning-10);
    gap: 12px;
    width: 100%;

    .noAvailIcon {
      color: var(--color-warning);
      font-size: 30px;
    }

    .noAvailDesc {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: var(--color-primary-text);
    }
  }
}
