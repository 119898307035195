@import 'src/assets/theme';

.container {
  direction: ltr;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  gap: 8px;

  &:hover,
  &.selected {
    background-color: var(--color-primary-10);
  }

  &.selectedItem {
    background-color: var(--color-primary-10);
  }

  &.disabled {
    cursor: not-allowed !important;

    &:hover {
      background-color: var(--color-white) !important;
    }

    .label {
      color: var(--color-grey-50) !important;
    }
  }
}

.label {
  color: var(--color-primary);
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.disabled {
    cursor: not-allowed !important;
    color: var(--color-grey-50) !important;

    &:hover {
      background-color: var(--color-white) !important;
    }
  }
}

.icon {
  font-size: 16px;
}

.navIcon {
  font-size: 16px;
  max-width: 16px;
}

.subMenuContainer {
  display: flex;
  align-items: flex-start;
}

.subMenu {
  position: absolute;
  margin-left: 28px;
  padding: 8px 0;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 145px;
  top: 0;
  max-height: 240px;
  overflow: auto;
  direction: rtl;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--color-primary);
  }

  > div {
    direction: ltr;
    padding: 8px 16px;
    &:hover,
    &.selected {
      background-color: var(--color-primary-10);
    }
  }
}
