@import 'src/assets/theme';

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  @media (min-width: $tablet-screen-max-content) {
    display: none;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .avatarContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .editAvatarButton {
      font-size: 14px;
      font-weight: 500;
    }

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 12px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: $tablet-screen-max-content) {
    padding: 20px 40px;
  }
}

.desktopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tablet-screen-max-content) {
    display: none;
  }

  .title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 700;
  }
}

.desktopRow {
  @media (min-width: $tablet-screen-max-content) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 36px;
  }

  .desktopAvatar {
    display: none;
    justify-content: center;
    align-items: center;
    width: 150px;
    border: dashed 1px var(--color-primary);
    border-radius: 50%;
    transition: background-color 0.1s ease;
    cursor: pointer;

    @media (min-width: $tablet-screen-max-content) {
      display: flex;
    }

    &:active {
      background-color: var(--color-primary-10);
    }
  }

  .desktopRow1,
  .desktopRow2,
  .desktopRow3,
  .desktopRow4 {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: $tablet-screen-max-content) {
      flex-direction: row;
      align-items: center;
    }
  }

  .desktopRow1 {
    justify-content: space-between;
  }

  .desktopRow2,
  .desktopRow3 {
    @media (min-width: $tablet-screen-max-content) {
      align-items: flex-end;

      > * {
        flex: 1 0 calc((100% - 20px) / 2);
      }
    }
  }

  .desktopRow4 {
    @media (min-width: $tablet-screen-max-content) {
      align-items: flex-start;

      > * {
        flex: 1 0 calc((100% - 20px) / 2);
      }
    }
  }
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobileInput {
  width: 218px;
}

.timezoneTip,
.communicationPreferenceTip {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-grey);
}

.mobileSaveButton {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;

  @media (min-width: $tablet-screen-max-content) {
    display: none;
  }
}
