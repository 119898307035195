@import 'src/assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 4px 8px 0px #0000001a;
  border: 1px solid var(--color-grey-10);
  border-radius: 8px;
  padding: 16px;

  @media (max-width: $tablet-screen-max-content) {
    margin-top: 12px;
  }

  .basicFormContainer {
    max-width: 800px;
    display: flex;
    flex-direction: column;
  }
  .nameWrapper {
    display: flex;
    gap: 16px;

    @media (max-width: $tablet-screen-max-content) {
      flex-direction: column;
    }

    .nameInput {
      width: 100%;
      max-width: 400px;
      color: #343434;
    }
  }

  .dobInputWrapper {
    margin: 12px 0 0;
  }

  .phoneInputWrapper {
    margin: 16px 0;
    max-width: 400px;
  }

  .emailInput {
    max-width: 400px;
  }

  .inputLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .customInput {
    transform: translateX(-4px);
    padding: 0 4px;
    color: #343434;
    margin-bottom: 4px !important;

    input {
      width: 100%;
    }
  }

  .customSelect {
    margin-bottom: 4px !important;
  }

  .selectInputLabel {
    font-weight: 600;
  }

  .createAccountContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    margin-top: 16px;

    .createAccountHeader {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .createAccountTitle {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .logoImage {
          height: 25px;
        }

        @media (max-width: $tablet-screen-max-content) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .createAccountSubTitle {
        font-size: 14px;
      }
    }

    .passwordInput {
      max-width: 500px;
    }
  }

  .showPasswordButtonClass {
    color: var(--color-warning) !important;
    min-width: max-content !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .customLabel {
    padding-left: 16px;
    color: #202225;
  }

  .subHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 6px;
  }

  .passwordHint {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    .hints {
      //padding-top: $standard-padding * 3;

      ul {
        padding: 0;

        li {
          list-style: none;
          //padding: $standard-padding * 1 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;

          &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            height: 20px;
            //margin-right: $standard-padding * 4;
            width: 20px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .passwordHintWithError {
    margin-top: 0;
  }

  .checkBoxText {
    display: flex;
    flex-direction: row;
    height: fit-content;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    .checkBox {
      vertical-align: top;
      align-items: center;

      input {
        outline: none;
        border: 2px solid var(--color-grey-50);
        margin-right: 8px;
      }
    }

    a {
      text-decoration: underline;
      color: var(--color-clickable-text);
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
    flex-direction: row;
    column-gap: 8px;

    @media (max-width: $mobile-screen-max-content) {
      flex-direction: column;
      gap: 20px;
    }

    .submitButtonWrapper {
      @media (max-width: $mobile-screen-max-content) {
        width: 100%;
      }
    }

    .submitButton {
      .rightArrow {
        font-size: 12px;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #ffffffc0;
  position: fixed;
  z-index: 2;
}

.link {
  display: inline-flex;
  align-items: center;
  color: --var(--color-clickable-text);
  font-weight: 600;

  .linkIcon {
    font-size: 20px;
    padding-left: 4px;
  }
}
