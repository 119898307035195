.modalContainer {
  width: 760px !important;

  // @media (max-width: $medium-small-screen-min-width) {
  //   width: unset !important;
  // }
}

.container {
  padding: 22px 20px 28px 22px;
  display: flex;
  gap: 20px;

  .infoIcon {
    color: #263054;
  }

  .alertContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // color: $colorGrey700;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  .link {
    display: flex;
    gap: 10px;
    color: #2a4670;

    .backIcon {
      font-size: 18px;
      margin-top: 1px;
    }
  }
}
