$large-screen-min-width: 1440px;
$medium-large_screen-min-width: 992px;
$medium-screen-min-width: 768px;
$medium-small-screen-min-width: 500px;
$small-screen-min-width: 320px;
$small-screen-max-width: 767px;

$medium-screen-min-height: 768px;
$medium-small-screen-min-height: 600px;

$desktop-screen-max-content: 1440px;
$small-desktop-screen-max-content: 1100px;
$tablet-screen-max-content: 992px;
$mobile-screen-max-content: 600px;

$medium-desktop-screen-max-content: 1280px;
