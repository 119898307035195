@import 'src/assets/theme';

.container {
  text-align: center;
  position: relative;

  .menuTrigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.8;
    }

    @media (min-width: $tablet-screen-max-content) {
      padding: 16px;

      &:hover {
        opacity: 1;
        background-color: var(--color-primary-50);

        .menuIcon {
          color: var(--color-primary-inverted);
        }
      }
    }

    .menuIcon {
      font-size: 30px;
      user-select: none;
    }
  }

  .activeMenu {
    background-color: var(--color-primary-50);

    .menuIcon {
      color: var(--color-primary-inverted);
    }
  }

  .menu {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -24px;
    right: -8px;
    z-index: 100;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: ease 0.2s;
    overflow: hidden;
    pointer-events: visible;

    @media (min-width: $tablet-screen-max-content) {
      top: calc(100% + 24px);
      right: 0;
      width: max-content;
      min-width: 350px;
      height: max-content;
      border: 1px solid var(--color-primary-50);
      border-radius: 12px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    &.showMenu {
      visibility: unset;
      opacity: 1;
    }
  }

  .menuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    height: 100%;
    overflow: auto;

    @media (min-width: $tablet-screen-max-content) {
      max-height: calc(100vh - 150px);
      background: unset;

      .footer {
        display: none;
      }
    }

    .footer {
      position: static;
    }
  }

  .menuBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 16px;
    font-weight: 600;
    font-size: 16px;

    @media (min-width: $tablet-screen-max-content) {
      padding: 8px 16px;
    }

    .header {
      display: flex;
      padding: 8px;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $tablet-screen-max-content) {
        display: none;
      }

      .logoLoading {
        display: flex;

        .logoLoadingImg {
          width: 150px;
          height: 50px;
          border-radius: 8px;
        }
      }

      .logoImage {
        max-height: 80px;
      }
    }

    .profileWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .profile {
        width: 100px;
        margin-bottom: 4px;

        .avatar {
          width: 60px;
          height: 60px;
        }
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        color: var(--color-primary-text);
      }

      .dateOfBirth,
      .email {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: var(--color-grey);
      }
    }

    .title {
      color: var(--primary-text-color);
    }

    .bookAppointment {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      padding: 12px 20px;
      font-size: 16px;
      line-height: 20px;
      box-shadow: none;

      .icon {
        font-size: 24px;
      }
    }

    .signOut {
      padding: 12px 0;
      cursor: pointer;
      transition: 0.2s ease;
      border-radius: 8px;

      &:hover {
        background-color: var(--color-warning-30);
      }

      @media (min-width: $tablet-screen-max-content) {
        color: var(--color-primary-text);
      }
    }
  }
}
