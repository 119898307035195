@import 'src/assets/theme';

.container {
  &:focus-within {
    .labelContainer .label {
      color: var(--color-primary);
    }
  }
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-left: 4px;
  margin-bottom: 8px;

  @media (min-width: $tablet-screen-max-content) {
    justify-content: flex-start;

    .label {
      font-weight: 600;
    }
  }

  .label {
    font-size: 12px;
    user-select: none;
  }
}

.input {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid $colorGrey400;
  box-sizing: border-box;

  &:focus,
  &:active {
    border-color: var(--color-primary);
  }
}

.error {
  .label {
    color: $colorError;
  }

  .input {
    border-color: $colorError;
  }
}
