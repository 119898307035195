.container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 28px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }

    .fieldWrapper {
      width: 100%;
      max-width: 345px;
      color: var(--color-primary-text);

      .selectInputLabel {
        font-size: 12px;
        color: var(--color-primary-text);
      }
    }

    .medicareInfo {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-start;

      .medicareInput {
        border-radius: 4px;
        border: 1px solid var(--color-grey-50);
        padding: 2px 4px;
      }

      .medicareNumber {
        @extend .medicareInput;
        min-width: 100px;
        width: 100%;
      }

      .irn {
        @extend .medicareInput;
        width: 10%;
        min-width: 50px;
      }
    }
  }

  .medicareImg {
    width: 50%;
    background-color: #edf1ff;
    border-radius: 4px;
    padding: 10px 15px;

    @media (max-width: 800px) {
      width: 100%;
    }

    .desc {
      font-size: 16px;
      color: var(--color-primary-text);
      display: flex;
      i {
        color: var(--color-primary);
        margin-right: 12px;
      }
    }

    img {
      width: 90%;
    }
  }
}
