.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  // @media screen and (max-width: ($medium-large_screen-min-width + 1)) {
  //   max-width: 540px;
  //   gap: 35px;
  // }
}

.file {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  .fileName {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    //color: $colorGrey700;

    i {
      font-size: 15px;
      //color: $blue500;
    }
  }
}

.uploadArea {
  //background: $someone-health-light-pink;
  //padding: 40px 60px 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  // @media (max-width: $medium-large_screen-min-width) {
  //   padding: 30px;
  // }
}

.uploadButtonContainer {
  width: fit-content;

  .uploadInput {
    display: none;
  }

  .uploadButton {
    display: flex;
    padding: 14px 20px;
    gap: 14px;
    border: solid 1px var(--color-primary);
    border-radius: 4px;
    color: var(--color-primary);
    cursor: pointer;
  }
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  .fileNameContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .removeButton {
      color: var(--color-warning);
      cursor: pointer;
    }
  }
}
