@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .avatarsContainer {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $tablet-screen-max-content) {
      width: 600px;
      grid-template-columns: 30% 30% 30%;
    }

    .avatarContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        position: relative;

        svg {
          z-index: 1;
          width: 90%;
          height: 90%;
          margin: auto;
          cursor: pointer;
        }

        &.selected {
          &::before {
            content: ' ';
            border: 5px solid var(--color-primary-50);
            width: 86px;
            height: 86px;
            border-radius: 100%;
            position: absolute;
            left: 7%;
            top: 14%;
            box-sizing: border-box;
          }

          &::after {
            content: url(./flat_tick_icon.svg);
            position: absolute;
            width: 20%;
            height: 20%;
            right: 3%;
            top: 8%;
          }
        }
      }

      .moreLikeThis {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: var(--color-primary);
        cursor: pointer;
        align-items: center;
        user-select: none;
        white-space: nowrap;

        .redoIcon {
          line-height: 20px;
          font-size: 12px;
        }

        .redoLabel {
          line-height: 20px;
          padding-left: 2px;
          padding-top: 2px;
          font-size: 14px;
          font-weight: 600;
          font-style: italic;
          text-align: center;

          @media only screen and (max-width: $medium-screen-min-width) {
            font-size: 10px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
