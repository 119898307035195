@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px 0 24px;

  @media (max-width: $medium-desktop-screen-max-content) {
    padding: 8px 0 30px;
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    color: var(--filter-text-color);

    @media (max-width: $medium-desktop-screen-max-content) {
      flex-direction: column;
      align-items: flex-start;
    }

    .header {
      display: flex;
      justify-content: space-between;

      @media (max-width: $medium-desktop-screen-max-content) {
        padding-bottom: 30px;
        align-items: center;
        width: 100%;
      }
    }

    .label {
      font-size: 18px;
      line-height: 26px;
      margin-right: 16px;
      font-weight: 700;
    }

    .filter {
      width: auto;
      flex: 1;
      max-width: 70%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
