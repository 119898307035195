.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--color-primary-text);
  padding: 0 7%;
}

.contents {
  background-color: var(--color-white);
  gap: 20px;
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }

  .leftContent {
    width: 65%;
    border-radius: 8px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .rightContent {
    width: 35%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
