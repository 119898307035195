.rowWrapper {
  display: flex;
  gap: 28px;
  margin-bottom: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .fieldWrapper {
    width: 100%;
    max-width: 400px;
    color: var(--color-primary-text);

    .selectInputLabel {
      font-size: 12px;
      color: var(--color-primary-text);
    }
  }
}
