@import 'src/assets/theme';

.icon {
  font-size: 20px;
  color: var(--color-grey);

  &:hover,
  &:active {
    color: var(--color-primary);
  }
}
