@import 'src/assets/theme';

.container {
  &:focus-within .label {
    color: var(--color-primary);
  }
}

.select {
  width: 100%;
  border-radius: 8px;

  &.error,
  &.noSpacing {
    margin-bottom: 0;
  }
}

.label {
  margin-bottom: 8px;
  color: var(--color-grey);
  font-size: 12px;

  @media (min-width: $tablet-screen-max-content) {
    font-weight: 600;
  }
}

.loadingWrapper {
  display: flex;
}
