@import 'src/assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;
  margin-top: 18px;
  flex-direction: column;

  .inputContainer {
    position: relative;
    width: 100%;
  }

  .field {
    padding-left: 4px;
    border: 0;
    border-bottom: 1px solid $colorGrey400;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    background: transparent;

    @media (min-width: $tablet-screen-max-content) {
      font-size: 16px;
    }

    &:valid {
      border-bottom: 1px solid $colorGrey400;
      + {
        .label {
          color: var(--color-grey);
          font-size: 12px;
          left: 4px;
          bottom: 100%;

          @media (min-width: $tablet-screen-max-content) {
            font-weight: 600;
          }
        }
      }
    }

    &:focus {
      border-bottom: 1px solid var(--color-primary);
      + {
        .label {
          color: var(--color-primary);
          font-size: 12px;
          left: 4px;
          bottom: 100%;

          @media (min-width: $tablet-screen-max-content) {
            font-weight: 600;
          }
        }
      }
    }

    &.error {
      border-bottom: 1px solid $colorError;
      + {
        .label {
          color: $colorError;
          font-size: 12px;
          left: 4px;
          bottom: 100%;

          @media (min-width: $tablet-screen-max-content) {
            font-weight: 600;
          }
        }
      }
    }
  }

  .label {
    bottom: 2px;
    left: 4px;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 14px;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    color: var(--color-grey);
    user-select: none;

    @media (min-width: $tablet-screen-max-content) {
      font-size: 16px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.showPasswordButton {
  position: absolute;
  right: 12px;
  bottom: 0;
  padding: 0;
  border: none !important;
}
