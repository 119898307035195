@import 'src/assets/theme';

.title {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: var(--color-primary-50);
}

.form,
.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.line1InputContainer {
  position: relative;
}

.desktopRow {
  @media (min-width: $tablet-screen-max-content) {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    > * {
      flex: 1 0 calc((100% - 20px) / 2);
    }

    .column {
      height: 100%;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: $tablet-screen-max-content) {
    gap: 20px;
  }
}
