@import 'src/assets/theme';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .header {
    display: flex;

    .logo {
      max-height: 65px;

      @media (max-height: $medium-small-screen-min-height) {
        max-height: 50px;
      }
    }
  }
}
