@import 'src/assets/theme';

.container {
  display: flex;
  width: 90%;
  align-items: center;
  column-gap: 16px;

  @media (max-width: $medium-desktop-screen-max-content) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }

  .mentalHealthConcern {
    max-width: 65%;
    width: 100%;

    @media (max-width: $medium-desktop-screen-max-content) {
      max-width: 100%;
    }

    .mentalHealthDropDown {
      min-height: 48px;
    }
  }

  .secondLayerFilterWrapper {
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: $medium-desktop-screen-max-content) {
      width: 100%;
      margin-top: 40px;
    }

    .ageRange {
      width: 100%;
      margin-right: 16px;
    }

    .ageDropDown {
      min-height: 48px;
    }

    .gender {
      max-width: 220px;
      width: 100%;

      @media (max-width: $medium-desktop-screen-max-content) {
        max-width: 50%;
      }
    }

    .reset {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--filter-text-color);
      font-size: 14px;
      cursor: pointer;
      user-select: none;
      font-weight: 700;
      margin: 0 8px;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
