.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .imageContainer {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .image {
    width: 100%;
    border-radius: 50%;
  }

  .imagePlaceholder {
    display: flex;
    align-items: center;
    font-size: 40px;
    font-variation-settings: 'FILL' 1;
    color: var(--color-white);
  }
}
