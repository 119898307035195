@import 'src/assets/theme';

.container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.fakeInputContainer {
  flex: 1;
}

.label {
  margin-left: 4px;
  margin-bottom: 8px;
  font-size: 12px;
  user-select: none;

  @media (min-width: $tablet-screen-max-content) {
    font-weight: 600;
  }
}

.fakeInput {
  width: 100%;
  padding-left: 4px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid $colorGrey400;
}

.button {
  padding: 11px 16px;
  border: none !important;
}
