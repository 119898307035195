@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .dateTitle {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: var(--color-header-text);
  }

  .timeSlotWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    max-height: 290px;
    overflow: auto;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-primary);
    }

    @media (max-width: $medium-desktop-screen-max-content) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $small-desktop-screen-max-content) {
      grid-template-columns: repeat(3, 1fr);
    }

    .timeSlot {
      color: var(--color-primary-text);
      cursor: pointer;

      &.active {
        background: var(--color-primary);
        color: $white;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .noSlotWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    color: var(--color-grey);
  }
}

.loadingContainer {
  @extend .container;

  .loadingHeaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loadingTitle {
      height: 24px;
      width: 100%;
      border-radius: 30px;
      max-width: 160px;
      margin-top: 2px;
      margin-bottom: $standard-padding;
    }
  }

  .loadingSlotWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;

    .loadingSlot {
      height: 30px;
      width: 100%;
      min-width: 100%;
      border-radius: 30px;
      max-width: 40px;
    }
  }
}
