@import 'assets/theme';

.container {
  margin-bottom: $standard-padding * 2;
  width: fit-content;

  .status {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    padding: 13px 21px;
    border-radius: 5px;
    background: #fff5f5;

    .loading {
      display: flex;
      justify-content: center;
      width: 24px;
      margin-right: $standard-padding * 1.5;
    }

    .icon {
      margin-right: $standard-padding * 1.5;
      font-size: 24px;
      color: #63c364;
    }

    .iconWarning {
      @extend .icon;
      color: #ff7d45;
    }

    .iconInvalid {
      @extend .icon;
      color: #d54141;
    }
  }

  .validStatus {
    @extend .status;
    background: #f1fff7;
  }

  .loadingStatus {
    @extend .status;
    background: #fff;
  }

  .description {
    padding-left: $standard-padding * 4.5;
    padding-right: $standard-padding;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #61666e;
  }
}
