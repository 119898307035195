@import 'src/assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--color-available-30);
  background: var(--color-available-10);
  padding: 8px 16px;
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  transition: all 0.1s;

  &.active {
    background: var(--color-available) !important;
  }
}
