.progressBar {
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 5px;

    .step {
      text-align: center;
      background: var(--color-grey-10);
      border-radius: 50%;
      width: 24px;
      height: 24px;

      &.completed {
        border: 3px solid var(--color-white);
        width: 18px;
        height: 18px;
      }

      &.current {
        background: var(--color-primary-50);
        border: 3px solid var(--color-primary);
        font-weight: bold;
        width: 18px;
        height: 18px;
      }
    }

    .line {
      flex: 1;
      height: 1px;
      background: var(--color-grey-50);
    }

    .label {
      font-size: 16px;
      max-width: 20%;
      &.current {
        font-weight: 700;
      }
    }
  }

  .stepRow {
    @extend .row;
    justify-content: space-around;
  }
}
