@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 1.3;
  color: var(--color-primary-text);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
    font-size: 24px;
    color: var(--color-header-text);
  }

  @media (min-width: $tablet-screen-max-content) {
    font-size: 24px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 36px;
    }
  }

  strong {
    font-weight: 600;
  }

  .welcomeMessage {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-header-text);

    @media (min-width: $tablet-screen-max-content) {
      font-size: 36px;
    }
  }
}
