@import 'src/assets/theme';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--filter-bg-color);
  background-repeat: no-repeat;
  background-attachment: fixed;

  .containerBox {
    gap: 20px;
    flex: 1;

    .content {
      display: flex;
      flex: 1;
      margin: 40px 0 60px;

      @media (max-width: $small-desktop-screen-max-content) {
        flex-direction: column;
      }

      .fixCardWrapper {
        display: flex;
        flex-direction: column;
        margin-right: 24px;
        width: 100%;
        max-width: 350px;

        @media (max-width: $small-desktop-screen-max-content) {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 24px;
        }

        .followCard {
          display: flex;
          flex-direction: column;
          position: fixed;
          max-width: 350px;
          width: 100%;
          padding: 20px;
          background-color: var(--color-white);
          border-radius: 8px;
          border: 1px solid var(--color-grey-10);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
          box-sizing: border-box;

          @media (max-width: $small-desktop-screen-max-content) {
            position: inherit;
            max-width: 100%;
            padding: 16px;
          }

          .clinicianInfoWrapper {
            display: flex;
            flex-direction: column;

            @media (max-width: $small-desktop-screen-max-content) {
              flex-direction: row;
              align-items: center;
            }

            .avatarWrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin: 22px 0;

              @media (max-width: $small-desktop-screen-max-content) {
                margin: 0;
              }

              .avatarImg {
                position: absolute;
                top: -100px;
                width: 120px;
                border-radius: 50%;
                margin-bottom: 16px;

                @media (max-width: $small-desktop-screen-max-content) {
                  position: initial;
                }
              }
            }

            .nameWrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              @media (max-width: $small-desktop-screen-max-content) {
                justify-content: flex-start;
              }

              .name {
                display: flex;
                font-size: 28px;
                font-weight: 700;
                line-height: 36px;
              }

              .roleSection {
                display: flex;

                .divider {
                  margin: 0 4px;
                }

                .roleBox {
                  display: flex;
                  align-items: center;

                  .verifiedIcon {
                    margin-left: 4px;
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .nextAvailableSection {
            display: flex;
            flex-direction: column;
            padding: 30px 0;

            .nextLabel {
              color: var(--color-secondary-text);
              font-size: 12px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0.48px;
              text-transform: uppercase;
              margin-bottom: 4px;
            }

            .timeSlotCard {
              display: flex;
              flex-direction: column;
              gap: 12px;
            }

            .nextAvailableCard {
              display: flex;
              align-items: center;
              background-color: var(--color-secondary-10);
              padding: 10px 12px;
              overflow: auto;

              &::-webkit-scrollbar {
                width: 10px;
                height: 3px;
              }

              &::-webkit-scrollbar-track {
                background-color: var(--color-grey-10);
                border: 3px solid transparent;
                border-radius: 5px;
                background-clip: content-box;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: var(--color-primary-50);
                cursor: pointer;
              }

              .dateWrapper {
                display: flex;
                flex-direction: column;

                .date {
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 23px;
                }

                .month {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 21px;
                }

                .day {
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 16px;
                }
              }

              .timeslotWrapper {
                display: flex;
                align-items: center;
                gap: 24px;
                margin-left: 24px;
              }
            }
          }

          .anchorSection {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-top: 1px solid var(--color-grey-50);
            padding-top: 20px;
            margin-top: 20px;

            @media (max-width: $small-desktop-screen-max-content) {
              display: none;
            }

            .anchorLink {
              color: var(--color-primary);
              font-size: 16px;
              font-weight: 700;
              line-height: 21px;
              cursor: pointer;
            }

            .anchorLinkActive {
              @extend .anchorLink;
              color: var(--color-clickable-text);
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-decoration-thickness: auto;
              text-underline-offset: auto;
              text-underline-position: from-font;
            }
          }
        }
      }

      .detailsWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        .infoCard {
          border-radius: 8px;
          border: 1px solid var(--color-grey-10);
          background: var(--color-white);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
          padding: 16px 16px;

          .bigTitle {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }

          .title {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
          }

          .secondaryTitle {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            margin-top: 20px;
            color: var(--color-grey);
          }

          .desc {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            white-space: pre-line;
          }

          .verticalWrapper {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .specialisationWrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 8px;
          }

          .qualificationsWrapper {
            display: flex;
            align-items: center;
            margin-top: 8px;

            .graduateIcon {
              background-color: var(--color-grey-10);
              border-radius: 50px;
              color: var(--color-primary);
              font-variation-settings: 'FILL' 1;
              margin-right: 8px;
              padding: 4px;
              font-size: 20px;
            }
          }

          .specialisation {
            display: flex;
            align-items: center;
            width: max-content;
            padding: 4px 8px;
            border-radius: 30px;
            background-color: var(--color-grey-10);
            margin: 0 8px 8px 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;

            &:last-child {
              margin-right: 0;
            }
          }

          .deliveryModeIcon {
            background-color: var(--color-grey-10);
            border-radius: 50px;
            color: var(--color-primary);
            font-variation-settings: 'FILL' 1;
            margin-right: 4px;
            font-size: 18px;
          }

          .fundingIcon {
            background-color: var(--color-grey-10);
            border-radius: 50px;
            color: var(--color-primary);
            margin-right: 4px;
            font-size: 18px;
            padding: 4px;
            font-variation-settings: 'FILL' 1;
          }

          .fundingModeWrapper {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
          }

          .appointmentOfferWrapper {
            display: flex;
            flex-direction: column;

            .appointmentOffer {
              display: flex;
              align-items: center;

              &:not(:first-child) {
                margin-top: 8px;
              }
            }

            .appointmentOfferBtn {
              margin-left: 12px;
              padding: 8px 12px;

              @media (max-width: $medium-screen-min-height) {
                display: none;
              }

              .arrowIcon {
                font-size: 12px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.footerWrapper {
  margin-top: -20px;
  z-index: 1;
}
